import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getsavesignature } from "../../../StateManagement/Reducers/DocumentsState";

import Error from "../../../Modules/StyledComponents/Toastify/Error";

import {
  ModalTitle,
  Modal,
  ModalContainer,
  Div,
  Text,
} from "../../StyledComponents";
import {
  CancelButton,
  ModalDialog,
  Preview,
  SaveButton,
  TextArea,
  TextBox,
} from "./Styles";

import { BsXLg } from "react-icons/bs";

const CreateSignature = ({
  show,
  close,
  success,
  ids,
  content,
  closesave,
  forIdCheck,
  image,
  onDataPass,
  closesave1,
}) => {
  // ------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [ModalAnimat] = useState(true);
  const [disabledsave, setdisabledsave] = useState(false);
  const SignaturesavesignID = useSelector(
    (state) => state.Document.signaturesavesignID
  );
  const Refresh = useSelector((state) => state.Document.Refresh);
  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const CloseSignature = () => {
    setName("");
    close();
  };

  function displayValues(content) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    console.log(content, "content");
    const inputs = doc.querySelectorAll("input");

    inputs.forEach(function (input) {
      if (
        input.type === "text" ||
        input.type === "textarea" ||
        input.type === "date"
      ) {
        let value = input.value;
        if (value.trim() !== "") {
          const textNode = doc.createTextNode(value + " ");
          input.parentNode.replaceChild(textNode, input);
        }
      }
      if (input.type === "radio" && input.classList.contains("radiobox")) {
        if (input.type === "radio" && input.classList.contains("radiobox")) {
          if (input.checked) {
            let value = input.value.trim();

            if (input.parentNode) {
              if (
                input.nextSibling &&
                input.nextSibling.nodeType === Node.TEXT_NODE
              ) {
                input.parentNode.removeChild(input.nextSibling);
              }

              const textNode = document.createTextNode(value);
              input.parentNode.replaceChild(textNode, input);
            }
          }
        }
        if (!input.checked) {
          if (
            input.nextSibling &&
            input.nextSibling.nodeType === Node.TEXT_NODE
          ) {
            input.parentNode.removeChild(input.nextSibling);
          }
          input.parentNode.removeChild(input);
        }
      }
      if (input.type === "radio" && input.classList.contains("radioboxGroup")) {
        if (input.checked) {
          let value = input.value;
          if (value.trim() !== "") {
            const textNode = doc.createTextNode(value + " ");
            input.parentNode.replaceChild(textNode, input);
          }
        } else {
          let value = "No";
          const textNode = doc.createTextNode(value + " ");
          input.parentNode.replaceChild(textNode, input);
        }
      }
    });

    const selectElements = doc.querySelectorAll("select");
    selectElements.forEach((selectElement) => {
      let selectedTexts = [];

      Array.from(selectElement.options).forEach((option) => {
        if (option.selected) {
          selectedTexts.push(option.text);
        }
      });

      if (selectedTexts.length > 0) {
        const textNode = doc.createTextNode(selectedTexts.join(", ") + " ");
        selectElement.parentNode.replaceChild(textNode, selectElement);
      } else {
        const textNode = doc.createTextNode("No selection made");
        selectElement.parentNode.replaceChild(textNode, selectElement);
      }
    });

    return doc.body.innerHTML;
  }
  const SaveSignature = () => {
    if (name === "") {
      toast(<Error msg="Add Your Signature" />, {
        containerId: "B",
        className: "error_badge",
      });
      closesave();
    } else {
      if (image === "notimage") {
        const modifiedHTMLString = displayValues(content);
        dispatch(
          getsavesignature({
            ids: forIdCheck === "yes" ? ids : SignaturesavesignID,
            sign: name,
            temp: modifiedHTMLString,
          })
        );
        setdisabledsave(true);
        closesave();
      } else {
        onDataPass(name);
        closesave1();
      }
    }
  };

  // ------------------------------Functions End-------------------------- //
  // ------------------------------UseEffect Start-------------------------- //
  useEffect(() => {
    if (Refresh === 4) {
      CloseSignature();
    }
  }, [Refresh]);

  // ------------------------------UseEffect End-------------------------- //
  return (
    <>
      <Modal show={show} height="100%" width="100%">
        <ModalContainer justifyContent="center">
          <ModalDialog animation={ModalAnimat}>
            <Div
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="0px 0px 8px 0px"
            >
              <ModalTitle
                lineHight="27px"
                fontSize="20px"
                fontWeight="600"
                color="#0a1629"
              >
                Create Your Signature
              </ModalTitle>
              <BsXLg
                style={{
                  color: "#407BFF",
                  cursor: "pointer",
                  height: "14px",
                  width: "14px",
                }}
                onClick={close}
              />
            </Div>
            <Div>
              <Text
                fontWeight="400"
                fontSize="14px"
                color="#7d8592"
                mb="16px"
                mt="0px"
                textAlign="left"
              >
                Updating your Information here will be applied to all future
                Signatures.
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#7d8592"
                mb="4px"
                mt="0px"
                textAlign="left"
              >
                Your Name
              </Text>
              <TextBox
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#7d8592"
                mb="0px"
                mt="0px"
                textAlign="left"
              >
                Preview
              </Text>
              <Preview>
                <TextArea as="textarea" value={name} disabled={true} />
              </Preview>
              <Div
                display="flex"
                flexDirection="row"
                justifyContent="end"
                margin="16px 0px 0px 0px"
              >
                <CancelButton
                  onClick={CloseSignature}
                  id="sig_cnl-btn"
                  className="close_active"
                >
                  Cancel
                </CancelButton>
                <SaveButton
                  disabled={disabledsave}
                  onClick={SaveSignature}
                  className="save_active"
                >
                  Save
                </SaveButton>
              </Div>
            </Div>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default memo(CreateSignature);
