import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getDocFirstSave } from "../../../StateManagement/Reducers/DocumentsState";
import CreateSignature from "../CreateSignature";
import Error from "../../../Modules/StyledComponents/Toastify/Error";
import "./Test.css";
import {
  ModalTitle,
  Modal,
  ModalContainer,
  Div,
  Text,
} from "../../StyledComponents";
import {
  CancelButton,
  ModalDialog,
  Preview,
  SaveButton,
  TextArea,
  TextBox,
} from "./Styles";

import { BsXLg } from "react-icons/bs";

const ViewEdit = ({
  show,
  close,
  status,
  filename,
  filestatus,
  fileid,
  forsign,
}) => {
  // ------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({});
  const [name, setName] = useState("");
  const [Signature, setSignature] = useState(false);
  const [image, setImage] = useState("");
  const [imagename, setImagename] = useState("");
  const [viewdocumentdata1, setViewdocumentdata1] = useState("");
  const [ModalAnimat] = useState(true);
  const [disabledsave, setdisabledsave] = useState(false);
  const [ContentForSvaeSign, setContentForSvaeSign] = useState("");
  const SignaturesavesignID = useSelector(
    (state) => state.Document.signaturesavesignID
  );
  function addBorderToTDs(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const tds = doc.querySelectorAll("td");

    tds.forEach((td) => {
      td.style.border = "1px solid 	#000000";
      td.style.padding = "8px";
    });

    return doc.documentElement.outerHTML;
  }
  function disabledremove(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(ViewEditDocumentDataval, "text/html");
    const inputElements = doc.querySelectorAll("input, select,img");
    inputElements.forEach((input) => {
      input.removeAttribute("disabled");
    });

    return doc.documentElement.outerHTML;
  }
  const ViewEditDocumentDataBefore = useSelector(
    (state) => state.Document.ViewEditData
  );
  const ViewEditDocumentDataval = addBorderToTDs(ViewEditDocumentDataBefore);
  const ViewEditDocumentData = disabledremove(ViewEditDocumentDataval);
  const formElementRegex = /<(input|select|checkbox|radio|img)/i;
  const checkEditOrNot = formElementRegex.test(ViewEditDocumentData);
  const extractValuesBetweenTildes = (val, placeholder) => {
    const regex = /~(.*?)~/g;
    const matches = [];
    let match;

    while ((match = regex.exec(val)) !== null) {
      matches[match.index - placeholder.length] = match[1];
    }
    return matches;
  };

  let rowCounterMap = {};
  const handlePlaceholderReplace = (placeholder, index, inputValue) => {
    const id = placeholder.replace(/{|}/g, ""); // Remove curly braces
    const value = inputValues[id] || "";
    var valueofArray = extractValuesBetweenTildes(
      ViewEditDocumentData,
      placeholder
    );
    if (placeholder === "inputbox") {
      return `<input type="text" class="inputbox" data-input-id="${index}" value="${
        valueofArray[index] !== undefined ? valueofArray[index] : inputValue
      }"></input>`;
    } else if (placeholder === "datepicker") {
      return `<input type="date" data-input-id="${index}" class="datepicker" value="${
        valueofArray[index] !== undefined ? valueofArray[index] : value
      }"></input>`;
    } else if (placeholder === "signature") {
      return `<p style="font-style: italic;" class="signature" data-input-id="11" data-input-id="${index}" value="${
        valueofArray[index] !== undefined ? valueofArray[index] : imagename
      }"></input>`;
    } else if (placeholder === "radiobox") {
      return `<input type="radio" class="radiobox" data-input-id="${index}" value="Yes" name="${index}ynRadioGroup"
       ${
         valueofArray[index] !== undefined && valueofArray[index] === "Yes"
           ? "checked"
           : ""
       }
        >Yes</input>
        <input type="radio" class="radiobox" data-input-id="${index}" value="No" name="${index}ynRadioGroup" ${
        valueofArray[index] !== undefined && valueofArray[index] === "No"
          ? "checked"
          : ""
      }>No</input>`;
    } else if (placeholder === "radioboxGroup") {
      const lastTrIndex = ViewEditDocumentData.lastIndexOf("<tr", index);

      if (!rowCounterMap.hasOwnProperty(lastTrIndex)) {
        rowCounterMap[lastTrIndex] = 0;
      }

      rowCounterMap[lastTrIndex]++;

      const counter = rowCounterMap[lastTrIndex];

      const name = "radioGroup" + lastTrIndex;

      return `<input type="radio" class="radioboxGroup" data-input-id="${index}" value="${counter}" name="${name}" ${
        valueofArray[index] !== undefined &&
        valueofArray[index] === counter.toString()
          ? "checked"
          : ""
      }></input>`;
    } else {
      return placeholder;
    }
  };

  const ModifiedStringFin = ViewEditDocumentData.replace(
    /{[^}]+}/g,
    (match, index) => {
      const inputValue = inputValues[match];
      return handlePlaceholderReplace(match, index, inputValue);
    }
  );
  const handleUpdate = () => {
    const ModifiedStringFinal = ModifiedStringFin; // Assign modified content
    setViewdocumentdata1(ModifiedStringFinal); // Set the updated string in the state
  };
  const duplicateStringBefore = useSelector(
    (state) => state.Document.ViewEditData
  );
  const duplicateStringDataval = addBorderToTDs(duplicateStringBefore);
  const duplicateString = disabledremove(duplicateStringDataval);
  useEffect(() => {
    const handleInputChange = (event) => {
      const input = event.target;
      const id = input.getAttribute("data-input-id");
      const value = input.value;

      if (input.tagName === "SELECT" && input.type !== "radio") {
        if (input.multiple) {
          const selectedValues = Array.from(input.selectedOptions).map(
            (option) => option.value
          );

          setInputValues((prevValues) => ({
            ...prevValues,
            [id]: selectedValues,
          }));
        } else {
          const selectedValue = input.value;
          setInputValues((prevValues) => ({
            ...prevValues,
            [id]: selectedValue,
          }));
        }
      } else if (
        input.type === "radio" &&
        input.checked &&
        input.tagName !== "SELECT"
      ) {
        const radioGroupName = input.name;
        const radioButtons = document.querySelectorAll(
          `input[name="${radioGroupName}"]`
        );
        radioButtons.forEach((radio) => {
          if (radio !== input) {
            radio.removeAttribute("checked");
          }

          input.setAttribute("checked", "checked");
          if (radio.classList.contains("radioboxGroup")) {
            radio.setAttribute("value", "Yes");
          }
        });
        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }));
      }
    };

    const handleImageClick = () => {
      setSignature(true);

      setImage("fromimage");
    };

    const attachEventListeners = () => {
      const selectInputs = document.querySelectorAll("select[data-input-id]");

      selectInputs.forEach((select) => {
        select.addEventListener("change", handleInputChange);
      });

      const radioBoxInputs = document.querySelectorAll(
        'input[type="radio"].radiobox[data-input-id]'
      );
      const radioBoxGroupInputs = document.querySelectorAll(
        'input[type="radio"].radioboxGroup[data-input-id]'
      );

      radioBoxInputs.forEach((input) => {
        input.addEventListener("change", handleInputChange);
      });

      radioBoxGroupInputs.forEach((input) => {
        input.addEventListener("change", handleInputChange);
      });

      const signatureImage = document.querySelector(
        "img.signature[data-input-id]"
      );
      if (signatureImage) {
        signatureImage.addEventListener("click", handleImageClick);
      }
      const signatureImages = document.querySelectorAll("img.signature");
      signatureImages.forEach((signatureImage) => {
        signatureImage.addEventListener("click", handleImageClick);
      });
      return () => {
        selectInputs.forEach((select) => {
          select.removeEventListener("change", handleInputChange);
        });
        radioBoxInputs.forEach((input) => {
          input.removeEventListener("change", handleInputChange);
        });
        radioBoxGroupInputs.forEach((input) => {
          input.removeEventListener("change", handleInputChange);
        });
        if (signatureImage) {
          signatureImage.removeEventListener("click", handleImageClick);
        }
        return () => {
          signatureImages.forEach((signatureImage) => {
            signatureImage.removeEventListener("click", handleImageClick);
          });
        };
      };
    };

    const cleanup = attachEventListeners();

    const observer = new MutationObserver(() => {
      cleanup();
      attachEventListeners();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      cleanup();
      observer.disconnect();
    };
  }, []);
  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const CloseSignature = () => {
    setName("");
    setImagename("");

    close();
  };
  const SaveSignature = () => {
    const inputValues1 = {};
    const inputElements1 = document.querySelectorAll("input, select");
    let AddSign = false;

    inputElements1.forEach((inputElement) => {
      const inputId1 = inputElement.getAttribute("data-input-id");
      const inputValue1 = inputElement.value || "";

      const imgElement = document.querySelector(".signature");

      if (
        (inputElement.tagName === "SELECT" &&
          ((inputElement.multiple &&
            (!inputValues1[inputId1] || inputValues1[inputId1].length === 0)) ||
            (!inputElement.multiple &&
              inputElement.value === "Please Select One"))) || // Invalid multi-select or unselected single-select
        (inputElement.type === "radio" &&
          !inputElement.classList.contains("radioboxGroup") &&
          !Array.from(document.getElementsByName(inputElement.name)).some(
            (radio) => radio.checked
          )) || // No radio selected
        (inputElement.type !== "radio" &&
          inputElement.tagName !== "SELECT" &&
          inputValue1 === "") || // Empty text input
        (imgElement && imgElement.src && imgElement.src.trim() !== "")
      ) {
        AddSign = true;
      }
    });

    if (AddSign) {
      setSignature(true);
      setImage("notimage");
    } else {
      toast(<Error msg="To Sign, Fill Your Details and Save" />, {
        containerId: "B",
        className: "error_badge",
      });
    }
  };

  /////////////////////////////////ADD////////////////////

  const HanldeCloseSignature = () => {
    setSignature(false);
  };
  const HanldeCloseSignatureSave = () => {
    setSignature(false);
    close();
  };
  const HanldeCloseSignatureSave1 = () => {
    setSignature(false);
  };
  const handleChildData = (data) => {
    setImagename(data);
    const modifiedString = setStringreplace();
    setViewdocumentdata1(modifiedString);
    updateImageInDocument(modifiedString, data, "fromimagesave");
  };
  const setStringreplace = () => {
    const inputValues = {};

    const inputElements = document.querySelectorAll("input, select");

    inputElements.forEach((inputElement) => {
      const inputId = inputElement.getAttribute("data-input-id");
      let inputValue = inputElement.value;

      if (inputElement.type === "radio" && inputElement.checked === true) {
        inputValue = inputElement.value;
        inputValues[inputId] = inputValue;
      } else if (inputElement.tagName === "SELECT" && inputElement.multiple) {
        const selectedValues = Array.from(inputElement.selectedOptions).map(
          (option) => option.value
        );
        inputValues[inputId] = selectedValues;
      } else if (inputElement.tagName === "SELECT") {
        inputValues[inputId] = inputValue;
      }

      if (inputElement.type !== "radio" && inputElement.tagName !== "SELECT") {
        inputValues[inputId] = inputValue;
      }
    });

    // Initialize an object to store the group indexes
    const modifiedStringEditSave = duplicateString.replace(
      /<input[^>]+data-input-id="(\d+)"[^>]*>/g,
      (match, id) => {
        const inputValue = inputValues[id] || "";
        const isRadiobox = /class="radiobox"/.test(match);
        const isRadioboxGroup = /class="radioboxGroup"/.test(match);
        let isChecked = false;
        if (isRadiobox) {
          const radioMatch = match.match(/value="([^"]*)"/);
          if (radioMatch) {
            const radioValue = radioMatch[1];
            isChecked = inputValue === radioValue;
          }

          return match
            .replace(/checked="checked"/g, "")
            .replace(/>/, isChecked ? ' checked="checked">' : ">");
        }

        if (isRadioboxGroup) {
          const radioMatch = match.match(/data-input-id="([^"]*)"/);

          if (radioMatch !== null) {
            const radioDataInputId = radioMatch[1];

            if (inputValues[radioDataInputId] != null) {
              const radioValueMatch = match.match(/value="([^"]*)"/);
              const radioValue =
                radioValueMatch && radioValueMatch[1] !== ""
                  ? radioValueMatch[1]
                  : inputValues[radioDataInputId];

              isChecked = inputValues[radioDataInputId] === radioValue;
              return match
                .replace(/checked="checked"/g, "")
                .replace(
                  /value="[^"]*"/,
                  isChecked ? `value="${radioValue}"` : ""
                )
                .replace(/>/, isChecked ? ' checked="checked" >' : " >");
            }
          }
        }

        return match.replace(/value="[^"]*"/, `value="${inputValue}"`);
      }
    );

    const updatedStringWithSelect = modifiedStringEditSave.replace(
      /<select[^>]+id=["'](\d+)["'][^>]*>[\s\S]*?<\/select>/g,
      (match, id) => {
        const inputValue = inputValues[id]; // Get value by ID

        const selectedValues = Array.isArray(inputValue)
          ? inputValue
          : inputValue
          ? [inputValue]
          : []; // Handle empty value gracefully

        const updatedSelect = match.replace(
          /<option[^>]+value="([^"]+)"[^>]*>/g,
          (optionMatch, optionValue) => {
            const isSelected = selectedValues.includes(optionValue);
            return optionMatch
              .replace(/selected="selected"/g, "") // Remove existing selection
              .replace(">", isSelected ? ' selected="selected">' : ">");
          }
        );

        return updatedSelect;
      }
    );
    const targetContainer = document.querySelector(".target-container");
    if (targetContainer) {
      targetContainer.innerHTML = updatedStringWithSelect;
    }

    return updatedStringWithSelect;
  };
  const handleSave = () => {
    const modifiedString = setStringreplace();

    const parser = new DOMParser();
    const doc = parser.parseFromString(modifiedString, "text/html");

    doc.querySelectorAll("img.signature").forEach((img) => {
      const id = img.getAttribute("data-input-id");
      const p = document.createElement("p");
      p.setAttribute("style", "font-family: 'Shadows Into Light', cursive;");
      p.setAttribute("data-input-id", id);
      p.setAttribute("class", "signature");
      p.textContent = imagename;

      img.replaceWith(p);
    });
    const finalContent = doc.body.innerHTML;
    setContentForSvaeSign(finalContent);
    dispatch(
      getDocFirstSave({
        content: finalContent,
        id: fileid,
        type: "Add",
      })
    );
    close();
  };

  const SaveSignatureEdit = () => {
    const inputValues = {};
    const inputElements = document.querySelectorAll("input, select");
    let hasError = false;
    inputElements.forEach((inputElement) => {
      const inputId = inputElement.getAttribute("data-input-id");
      let inputValue = inputElement.value;

      if (inputElement.type === "radio" && inputElement.checked === true) {
        inputValue = inputElement.value;
        inputValues[inputId] = inputValue;
      } else if (inputElement.tagName === "SELECT" && inputElement.multiple) {
        const selectedValues = Array.from(inputElement.selectedOptions).map(
          (option) => option.value
        );
        inputValues[inputId] = selectedValues;
      } else if (inputElement.tagName === "SELECT") {
        inputValues[inputId] = inputValue;
      } else if (
        inputElement.type !== "radio" &&
        inputElement.tagName !== "SELECT"
      ) {
        inputValues[inputId] = inputValue;
      }

      if (!inputId || inputId === "null" || inputElement.disabled) {
        return;
      }
      const inputValue1 = inputElement.value || "";

      const imgElement = document.querySelector(".signature");
      if (
        (inputElement.tagName === "SELECT" &&
          ((inputElement.multiple &&
            (!inputValues[inputId] || inputValues[inputId].length === 0)) || // Multi-select validation
            (!inputElement.multiple &&
              inputElement.value === "Please Select One"))) || // No option selected in multi-select
        (inputElement.type === "radio" &&
          !inputElement.classList.contains("radioboxGroup") &&
          !Array.from(document.getElementsByName(inputElement.name)).some(
            (radio) => radio.checked
          )) || // No radio selected
        (inputElement.type !== "radio" &&
          inputElement.tagName !== "SELECT" &&
          inputValue1 === "") || // Empty text input
        (imgElement && imgElement.src && imgElement.src.trim() !== "")
      ) {
        hasError = true;
      }
    });
    if (hasError) {
      toast(<Error msg="To Sign, Fill Your Details and Save" />, {
        containerId: "B",
        className: "error_badge",
      });

      return;
    }
    const modifiedStringEditSave = duplicateString.replace(
      /<input[^>]+data-input-id="(\d+)"[^>]*>/g,
      (match, id) => {
        const inputValue = inputValues[id] || "";
        const isRadiobox = /class="radiobox"/.test(match);
        const isRadioboxGroup = /class="radioboxGroup"/.test(match);
        let isChecked = false;

        if (isRadiobox) {
          const radioMatch = match.match(/value="([^"]*)"/);
          if (radioMatch) {
            const radioValue = radioMatch[1];
            isChecked = inputValue === radioValue;
          }
          return match
            .replace(/checked="checked"/g, "")
            .replace(/>/, isChecked ? ' checked="checked">' : ">");
        }

        if (isRadioboxGroup) {
          const radioMatch = match.match(/data-input-id="([^"]*)"/);

          if (radioMatch !== null) {
            const radioDataInputId = radioMatch[1];

            if (inputValues[radioDataInputId] != null) {
              const radioValueMatch = match.match(/value="([^"]*)"/);
              const radioValue =
                radioValueMatch && radioValueMatch[1] !== ""
                  ? radioValueMatch[1]
                  : inputValues[radioDataInputId];

              isChecked = inputValues[radioDataInputId] === radioValue;
              return match
                .replace(/checked="checked"/g, "")
                .replace(
                  /value="[^"]*"/,
                  isChecked ? `value="${radioValue}"` : ""
                )
                .replace(/>/, isChecked ? ' checked="checked" >' : " >");
            }
          }
        }
        return match.replace(/value="[^"]*"/, `value="${inputValue}"`);
      }
    );

    const updatedStringWithSelect = modifiedStringEditSave.replace(
      /<select[^>]+id=["'](\d+)["'][^>]*>[\s\S]*?<\/select>/g,
      (match, id) => {
        const inputValue = inputValues[id]; // Get value by ID

        const selectedValues = Array.isArray(inputValue)
          ? inputValue
          : inputValue
          ? [inputValue]
          : [];
        const updatedSelect = match.replace(
          /<option[^>]+value="([^"]+)"[^>]*>/g,
          (optionMatch, optionValue) => {
            const isSelected = selectedValues.includes(optionValue);
            return optionMatch
              .replace(/selected="selected"/g, "") // Remove existing selection
              .replace(">", isSelected ? ' selected="selected">' : ">");
          }
        );

        return updatedSelect;
      }
    );

    const targetContainer = document.querySelector(".target-container");
    if (targetContainer) {
      targetContainer.innerHTML = updatedStringWithSelect;
    }
    return updatedStringWithSelect;
  };
  const handleSaveSign = () => {
    const modifiedString = SaveSignatureEdit();
    if (modifiedString === undefined) {
      return;
    } else {
      const parser = new DOMParser();
      const doc = parser.parseFromString(modifiedString, "text/html");

      doc.querySelectorAll("img.signature").forEach((img) => {
        const id = img.getAttribute("data-input-id");
        const p = document.createElement("p");
        p.setAttribute("style", "font-style: italic;");
        p.setAttribute("data-input-id", id);
        p.setAttribute("class", "signature");
        p.textContent = imagename;

        img.replaceWith(p);
      });
      const finalContent = doc.body.innerHTML;
      const inputValues1 = {};

      const inputElements1 = document.querySelectorAll("input, select");
      let AddError = false;
      inputElements1.forEach((inputElement) => {
        const inputId1 = inputElement.getAttribute("data-input-id");
        const inputValue1 = inputElement.value || "";
        const imgElement = document.querySelector(".signature");
        if (
          (inputElement.tagName === "SELECT" &&
            ((inputElement.multiple &&
              (!inputValues1[inputId1] ||
                inputValues1[inputId1].length === 0)) || // Multi-select validation
              (!inputElement.multiple &&
                inputElement.value === "Please Select One"))) || // No option selected in multi-select
          (inputElement.type === "radio" &&
            !Array.from(document.getElementsByName(inputElement.name)).some(
              (radio) => radio.checked
            )) || // No radio selected
          (inputElement.type !== "radio" &&
            inputElement.tagName !== "SELECT" &&
            inputValue1 === "") || // Empty text input
          (imgElement && imgElement.src && imgElement.src.trim() !== "")
        ) {
          AddError = true;
        }
      });
      setContentForSvaeSign(finalContent);

      if (AddError) {
        if (SignaturesavesignID) {
          dispatch(
            getDocFirstSave({
              content: finalContent,
              id: fileid,
              type: "Edit",
              forSign: "Yes",
            })
          );
        } else {
          dispatch(
            getDocFirstSave({
              content: finalContent,
              id: fileid,
              type: "Add",
              forSign: "Yes",
            })
          );
        }

        setSignature(true);
        setImage("notimage");
      } else {
        dispatch(
          getDocFirstSave({
            content: finalContent,
            id: fileid,
            type: "Edit",
            forSign: "Yes",
          })
        );
        setSignature(true);
        setImage("notimage");
      }
    }
  };
  const updateImageInDocument = (modifiedString, imagename, value) => {
    if (!imagename) return modifiedString;

    const parser = new DOMParser();
    const doc = parser.parseFromString(modifiedString, "text/html");

    doc.querySelectorAll("img.signature").forEach((img) => {
      const id = img.getAttribute("data-input-id");
      const p = document.createElement("p");
      p.setAttribute("style", "font-family: 'Shadows Into Light', cursive;");
      p.setAttribute("data-input-id", id);
      p.setAttribute("class", "signature");
      p.textContent = imagename;
      img.replaceWith(p);
    });

    const finalContent = doc.body.innerHTML;

    return finalContent;
  };
  // ------------------------------Functions End-------------------------- //
  // ------------------------------UseEffect Start-------------------------- //
  // useEffect(() => {
  //   setModifiedString(modifiedStringEdit);
  // }, [modifiedStringEdit]);
  useEffect(() => {
    if (!imagename) {
      handleUpdate();
    }
  }, [ViewEditDocumentData]);
  // ------------------------------UseEffect End-------------------------- //
  return (
    <>
      <Modal show={show} height="100%" width="100%">
        <ModalContainer justifyContent="center">
          <ModalDialog animation={ModalAnimat}>
            <Div
              borderBottom="1px solid #2E2E2E"
              height="50px"
              padding="0px 24px 0px 24px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="0px 0px 8px 0px"
            >
              <ModalTitle
                lineHight="27px"
                fontSize="18px"
                fontWeight="600"
                color="#0a1629"
              >
                Edit Document
              </ModalTitle>
              <BsXLg
                style={{
                  color: "#407BFF",
                  cursor: "pointer",
                  height: "14px",
                  width: "14px",
                }}
                onClick={close}
              />
            </Div>
            <Div overflow="auto" padding="0px 24px 0px 24px">
              <Div padding="20px" display="flex" justifyContent="space-between">
                <ModalTitle></ModalTitle>
                <ModalTitle
                  lineHight="27px"
                  fontSize="24px"
                  fontWeight="600"
                  color="#0a1629"
                >
                  {filename}
                </ModalTitle>

                <ModalTitle
                  lineHight="27px"
                  fontSize="18px"
                  fontWeight="600"
                  color="#138808"
                >
                  Status: {filestatus}
                </ModalTitle>
              </Div>

              <p
                dangerouslySetInnerHTML={{
                  __html: updateImageInDocument(
                    viewdocumentdata1,
                    imagename,
                    "fromdangorus"
                  ),
                }}
              />
            </Div>
            <Div
              padding="24px"
              display="flex"
              flexDirection="row"
              margin="auto 0px 0px 0px"
            >
              {checkEditOrNot !== null && checkEditOrNot === true ? (
                <>
                  <CancelButton
                    padding="8px 24px"
                    marginLeft="auto"
                    onClick={CloseSignature}
                    id="sig_cnl-btn"
                    className="close_active"
                  >
                    Cancel
                  </CancelButton>
                  <SaveButton
                    disabled={disabledsave}
                    onClick={handleSave}
                    className="save_active"
                  >
                    Save
                  </SaveButton>
                  {forsign === "1" && (
                    <CancelButton
                      width="150px"
                      marginLeft="20px"
                      padding="8px 9px"
                      onClick={handleSaveSign}
                    >
                      Save & Sign
                    </CancelButton>
                  )}
                </>
              ) : (
                <>
                  {forsign === "1" && (
                    <CancelButton
                      marginLeft="auto"
                      padding="8px 32px"
                      onClick={SaveSignature}
                    >
                      Sign
                    </CancelButton>
                  )}
                </>
              )}
            </Div>
          </ModalDialog>
        </ModalContainer>
      </Modal>
      {Signature && (
        <CreateSignature
          show={Signature}
          close={HanldeCloseSignature}
          closesave={HanldeCloseSignatureSave}
          closesave1={HanldeCloseSignatureSave1}
          ids={fileid}
          content={
            checkEditOrNot != null && checkEditOrNot === true
              ? ContentForSvaeSign
              : viewdocumentdata1
          }
          forIdCheck={
            checkEditOrNot != null && checkEditOrNot === true ? "no" : "yes"
          }
          image={image}
          onDataPass={handleChildData}
        />
      )}
    </>
  );
};

export default ViewEdit;
