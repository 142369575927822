import axiosInst from "../../Config";
import axios from "axios";

import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

var DBHost = "";

let firstDot = window.location.hostname.split(".");

if (
  firstDot[0] === "localhost" ||
  firstDot[0] === "test" ||
  firstDot[0] === "mumbai" ||
  firstDot[0] === "production" ||
  firstDot[0] === "ehr" ||
  firstDot[0] === "ehr1" ||
  firstDot[0] === "dev"
) {
  DBHost = "default";
} else {
  DBHost = firstDot[0];
}

const FacilityName = DBHost;

let backurl = "";

if (firstDot[0] !== "localhost") {
  backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
    "oemr",
    firstDot[0]
  );
} else {
  backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
}

export const CardDetails_Api = async () =>
  await axiosInst.get(`/vozoportal/patientcard/details?pid=${Pat_ID}`);

export const AddCardDetail_Api = async (data) => {
  const article = Encrypt_Value(
    {
      pid: Pat_ID,
      site: FacilityName,
      card_no: data.CardNumber,
      card_holder: data.CardHolderName,
      expireddate: data.ExpDate,
      cvv: data.CVV,
      def: data.DefaultCardVal,
      month: data.month,
      year: data.year,
      token: data.token,
      paymentMethod: data.paymentMethod,
    },
    "vozo"
  );

  return await axios.get(backurl + "/stripe-custom/new_cus_add_first_pay.php", {
    params: article,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
};

export const EditCardDetail_Api = async (data) => {
  const article = Encrypt_Value(
    {
      pid: Pat_ID,
      site: FacilityName,
      row_id: data.row_id,
      card_no: data.card_no,
      card_holder: data.card_holder,
      expireddate: data.expireddate,
      cvv: data.cvv,
      def: data.def,
      month: data.month,
      year: data.year,
    },
    "vozo"
  );
  return await axios.get(backurl + "/stripe-custom/pat_update_card.php", {
    params: article,
  });
};

export const RemoveCardDetail_Api = async (card_id) => {
  const article = Encrypt_Value(
    { pid: Pat_ID, site: FacilityName, row_id: card_id },
    "vozo"
  );
  return await axios.get(backurl + "/stripe-custom/delete_card.php", {
    params: article,
  });
};

export const InvoiceList_Api = async () =>
  await axiosInst.get(`/vozoportal/getinvoicelist?pid=${Pat_ID}`);

export const OneInvoiceList_Api = async (data) =>
  await axiosInst.get(`/vozoportal/getoneinvoicelist?search=` + data);

export const Extension_Api = async () =>
  await axiosInst.get("/vozoportal/getlogoext");

export const Base_Api = async (data) =>
  await axiosInst.post("/vozoportal/getlogobase", data);

export const currency_Api = async () =>
  await axiosInst.get("vozoportal/getcurrency");

export const MakePayment_Api = async (PayDetail) => {
  const PayDetail_data = Encrypt_Value(PayDetail, "vozoportal");

  let result = "";

  if (PayDetail.makepayment !== "yes") {
    const article = Encrypt_Value(
      {
        pid: Pat_ID,
        site: FacilityName,
        amount: PayDetail.amount,
        card_Id: PayDetail.card_Id,
        receipt_id: PayDetail.invoice_id,
      },
      "vozo"
    );
    await axios
      .get(backurl + "/stripe-custom/card_pay.php", { params: article })
      .then((res) => {
        if (res.data === "succeeded") {
          result = axiosInst.post(
            `/vozoportal/makepayment?pid=${Pat_ID}`,
            PayDetail_data
          );
        } else if (res.data.includes("No such customer")) {
          res.data = "No such customer";
          result = res;
        } else {
          result = "UnExpected Error Occured";
        }
      });
  } else {
    const article = Encrypt_Value(
      {
        pid: Pat_ID,
        site: FacilityName,
        card_Id: PayDetail.card_Id,
        invoice_data: PayDetail.invoice_data,
      },
      "vozo"
    );
    await axios
      .get(backurl + "/stripe-custom/allinvoices_pay.php", { params: article })
      .then((res) => {
        if (res.data === "succeeded") {
          result = axiosInst.post(
            `/vozoportal/dashboard/makepayment?pid=${Pat_ID}`,
            PayDetail_data
          );
        } else if (res.data.includes("No such customer")) {
          res.data = "No such customer";
          result = res;
        } else {
          result = "UnExpected Error Occured";
        }
      });
  }

  return result;
};

export const PaymentHistory_Api = async () =>
  await axiosInst.get(`/vozoportal/getPaymentHistory?pid=${Pat_ID}`);
