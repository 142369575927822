import jsPDF from "jspdf";
import "jspdf-autotable";

const invoiceGeneratePdf = (
  data,
  invNo,
  invdate,
  provider,
  facilityname,
  providerStreet,
  providerCity,
  providerState,
  providerNum,
  providerMail,
  patName,
  patNumber,
  patStreet,
  patCity,
  patState,
  patZip,
  patEmail,
  amount,
  balance,
  ext,
  Base,
  statelogo,
  symbol
) => {
  const doc = new jsPDF();

  let i = 0;

  const date = Date().split(" ");

  var rows = [];

  const cols = ["Date ", "Description", "Amount"];

  for (i = 0; i < data.length; i++) {
    rows.push([
      data[i]["date"],
      data[i]["serviceName"],
      `${symbol}${data[i]["fee"]}`,
    ]);
  }

  doc.setFontSize(13);
  doc.setFillColor(247, 247, 247);
  doc.rect(0, 0, 220, 35, "F");
  if (statelogo !== "0") {
    doc.addImage(Base, ext, 8, 8, 24, 21);
  }
  if (balance === "0.00") {
    doc.setFontSize(15);
    doc.setDrawColor(0, 128, 0);
    doc.roundedRect(60, 8, 30, 18, 2, 2, "FD");
    doc.setTextColor(0, 128, 0);
    doc.text(70, 19, "PAID");
  }
  doc.setDrawColor(0, 0, 0);
  doc.setTextColor(0, 0, 0);
  doc.text(130, 10, "Invoice");
  doc.text(130, 20, "Invoice id");
  doc.text(130, 30, "issue date");
  doc.setLineWidth(0.5);
  doc.line(155, 17, 155, 30);
  doc.text(160, 20, `${invNo}`);
  doc.text(160, 30, `${invdate}`);

  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0);
  doc.setFont("helvetica", "normal");
  doc.text(8, 50, "Bill from");
  doc.setTextColor(113, 128, 150);
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  let FacilityName = doc.splitTextToSize(facilityname, 120);
  doc.text(8, 56, FacilityName);
  providerStreet = providerStreet ? providerStreet + "," : "";
  providerCity = providerCity ? providerCity : "";
  providerState = providerState ? providerState : "";

  doc.text(8, 65, `${providerStreet}`);
  doc.text(8, 71, `${providerCity}`);
  doc.text(8, 78, `${providerState}`);
  patStreet = patStreet ? patStreet : "";
  patCity = patCity ? patCity : "";
  patState = patState ? patState : "";
  patZip = patZip ? -patZip : "";

  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0);
  doc.setFont("helvetica", "normal");
  doc.text(130, 50, "Bill to");
  doc.setTextColor(113, 128, 150);
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(130, 57, `${patName}`);
  doc.text(130, 65, `${patStreet}`);
  doc.text(130, 71, `${patCity}`);
  doc.text(130, 78, `${patState + patZip}`);
  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0);
  doc.setFont("helvetica", "normal");
  doc.text(8, 86, "Provider");
  doc.setFontSize(12);
  doc.setTextColor(113, 128, 150);
  doc.setFont("helvetica", "normal");
  doc.text(8, 92, `${provider}`);
  doc.text(8, 99, "Tax ID : ");
  providerNum = providerNum ? providerNum : "";
  doc.text(25, 99, `${providerNum}`);
  doc.text(8, 106, `${providerMail}`);

  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0);
  doc.setFont("helvetica", "normal");
  doc.text(130, 86, "Client");
  doc.setFontSize(12);
  doc.setTextColor(113, 128, 150);
  doc.setFont("helvetica", "normal");
  doc.text(130, 92, `${patName}`);
  patNumber = patNumber ? patNumber : "";
  doc.text(130, 99, `${patNumber}`);
  patEmail = patEmail ? patEmail : "";
  doc.text(130, 106, `${patEmail}`);

  doc.setDrawColor(247, 247, 247);
  doc.autoTable({
    head: [cols],
    body: rows,
    startY: 120,
    theme: "plain",
    margin: { left: 0, right: 0 },
    styles: {
      cellPadding: { top: 4, right: 5, bottom: 4, left: 8 },
      fontSize: 10,
    },

    headStyles: {
      fillColor: [247, 247, 247],
      textColor: [0, 0, 0],
      fontStyle: "normal",
      halign: "left",
      fontSize: 12,
    },
    bodyStyles: {
      fillColor: (255, 255, 255),
      fontStyle: "normal",
      halign: "left",
      fontSize: 12,
    },
    willDrawCell: (data) => {
      if (data.row.index != 0) {
        doc.setDrawColor(216, 224, 240);
        doc.setLineWidth(1);
        doc.line(
          data.cell.x + data.column.width,
          data.cell.y,
          data.cell.x,
          data.cell.y
        );
      }
    },
  });
  let final = doc.previousAutoTable.finalY;
  doc.setDrawColor(216, 224, 240);
  doc.setLineWidth(0.035);
  doc.line(0, final, 220, final);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0);
  doc.text(138, final + 20, "Total:");
  doc.text(150, final + 20, `${symbol}${amount}`);
  doc.text(110, final + 30, "Make Payments to:");
  const facilityName = doc.splitTextToSize(`${facilityname}`, 60);
  doc.text(150, final + 30, facilityName);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.setTextColor(113, 128, 150);
  doc.text("Powered by Vozo", doc.internal.pageSize.width / 2, 290, {
    align: "center",
  });

  doc.save(`report_${patName}_${invdate}.pdf`);
};

export default invoiceGeneratePdf;
