import React, { useState } from "react";

import InvoiceBalance from "./InvoiceBalance";
import PaymentHistory from "./PaymentHistory";
import Statement from "./Statement";

import { PageSubTitle } from "../../StyledComponents";
import { Div } from "../Styles";
import { Container, TabPanel, Tabs } from "./styles";

const Group = () => {
  const [opt, setOpt] = useState(1);
  const [invoOpt, setInvOpt] = useState(true);
  const [payOpt, setPayOpt] = useState(false);
  const [statementOpt, setStatementOpt] = useState(false);

  const handleTabs = (e) => {
    setOpt(e);
    setInvOpt(e === 1);
    setPayOpt(e === 2);
    setStatementOpt(e === 3);
  };

  return (
    <>
      <Container>
        <PageSubTitle padding="20px">
          {opt === 1
            ? "Invoice List"
            : opt === 2
            ? "Payment History"
            : "Statement"}
        </PageSubTitle>
        <TabPanel>
          <Tabs active={invoOpt} onClick={() => handleTabs(1)}>
            Invoice Balance
          </Tabs>
          <Tabs
            active={payOpt}
            onClick={() => handleTabs(2)}
            id="bil_invLs-pyHis"
          >
            Payment History
          </Tabs>
          <Tabs active={statementOpt} onClick={() => handleTabs(3)}>
            Statement
          </Tabs>
        </TabPanel>
        <Div marginTop="25px">
          {opt === 1 ? (
            <InvoiceBalance />
          ) : opt === 2 ? (
            <PaymentHistory />
          ) : (
            <Statement />
          )}
        </Div>
      </Container>
    </>
  );
};

export default Group;
