import React, { useState } from "react";
import { useSelector } from "react-redux";

import StatementTable from "./StatementTable";
import Pagination from "../../../StyledComponents/Pagination";

const Statement = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  const statementListDetails = useSelector(
    (state) => state.Billing.StatementList || []
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = statementListDetails.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const howManyPages = Math.ceil(statementListDetails.length / postsPerPage);

  return (
    <>
      <StatementTable StatementList={currentPosts} />
      {currentPosts.length === 0 || howManyPages === 1 ? (
        ""
      ) : (
        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
      )}
    </>
  );
};

export default Statement;
