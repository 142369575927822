import { createSlice } from "@reduxjs/toolkit";

export const PatientStatementDocsSlice = createSlice({
  name: "PatientStatementDocs",
  initialState: {
    provider: null,
    facility: null,
    date: null,
    insuranceBalance: 0,
    documents: [],
    loading: false,
  },
  reducers: {
    getPatientStatementDocs: (state) => {
      state.loading = true;
    },
    setPatientStatementDocs: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.documents = action.payload;
      } else {
        state.provider = action.payload.provider;
        state.facility = action.payload.facility;
        state.date = action.payload.date;
        state.insuranceBalance = action.payload.insuranceBalance;
        state.documents = action.payload.documents || [];
      }

      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { getPatientStatementDocs, setPatientStatementDocs, setLoading } =
  PatientStatementDocsSlice.actions;

export default PatientStatementDocsSlice.reducer;
