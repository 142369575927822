var DBHost = "";

let firstDot = window.location.hostname.split(".");

if (
  firstDot[0] === "localhost" ||
  firstDot[0] === "test" ||
  firstDot[0] === "mumbai" ||
  firstDot[0] === "production" ||
  firstDot[0] === "ehr" ||
  firstDot[0] === "ehr1" ||
  firstDot[0] === "dev"
) {
  DBHost = "default";
} else {
  DBHost = firstDot[0];
}

const FacilityName = DBHost;

let backurl = "";

if (firstDot[0] !== "localhost") {
  backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
    "oemr",
    firstDot[0]
  );
} else {
  backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
}

export { FacilityName, backurl };
