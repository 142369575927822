import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableHeadCell,
  TableBodyCell,
  Button,
} from "../../../StyledComponents";
import { Div } from "../../Styles";
import { TxtSpan } from "../styles";
import { useDispatch } from "react-redux";
import Loading from "../../../StyledComponents/Loading";
import axiosInst from "../../../../Config";
import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt";
import EmptyData from "../../../StyledComponents/EmptyData";

const StatementTable = () => {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/portal_statement_documents";

  useEffect(() => {
    axiosInst.get(`vozoportal/getdocumentlist?pid=${Pat_ID}`)
    .then((res) => {
      const decryptedResponse = Decrypt_Value(res.data, "vozoportal");
      setDocuments(decryptedResponse);
    })
    .catch((err) => {
      console.log(err);
    })
  }, []);

  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

    const handleDownload = async (pdfPath, id) => {
      if (!pdfPath) {
        console.error("Invalid PDF Path");
        return;
      }
    
      try {
        const res = await axiosInst.post(`/vozoportal/PreviewDownloadForPatientStatement?pid=${Pat_ID}&filePath=${pdfPath}`);
        
        if (res.data) {
          var tag = document.createElement("a");
          tag.href = res.data.file;
          tag.download = res.data.filename;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
        } else {
          console.error("Invalid response format");
        }
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };
    
  const handleView = (pdfPath) => {
    axiosInst.get(`/vozo/patientstatement/appointment?pid=24`)
    .then((res) => {
      console.log(Decrypt_Value(res.data))
    });
    if (!pdfPath) {
      console.error("Invalid PDF Path");
      return;
    }
    const pdfUrl = `${File_URL}/${pdfPath.split("/").pop()}`;
    window.open(pdfUrl, "_blank");
  }; 

  return (
    <Div>
      {loading ? (
        <Loading height={20} />
      ) : (
        <Table>
          <TableHead>
            <TableRow
              display="grid"
              tempColumns="15% 15% 15% 20% 35%"
              background="#F7F7F7"
            >
              <TableHeadCell padding="16px 0px 16px 38px">
                Provider
              </TableHeadCell>
              <TableHeadCell padding="16px 0px 16px 38px">
                Facility
              </TableHeadCell>
              <TableHeadCell padding="16px 0px 16px 38px">
                Generated On
              </TableHeadCell>
              <TableHeadCell padding="16px 0px 16px 38px">
                Statement Balance
              </TableHeadCell>
              <TableHeadCell padding="16px 0px 16px 38px">Action</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.length > 0 ? ( // ✅ Corrected conditional rendering
              documents.map((doc, index) => (
                <TableRow
                  key={index}
                  display="grid"
                  tempColumns="15% 15% 15% 20% 35%"
                >
                  <TableBodyCell padding="16px 0px 16px 38px" bodered>
                    <TxtSpan>{doc?.provider_name || "-"}</TxtSpan>
                  </TableBodyCell>
                  <TableBodyCell padding="16px 0px 16px 38px" bodered>
                    <TxtSpan>{doc?.facility_name || "-"}</TxtSpan>
                  </TableBodyCell>
                  <TableBodyCell padding="16px 0px 16px 38px" bodered>
                    <TxtSpan>
                      {doc?.document_date ? doc.document_date.split(" ")[0] : "-"}
                    </TxtSpan>
                  </TableBodyCell>
                  <TableBodyCell padding="16px 0px 16px 38px" bodered>
                    <TxtSpan>$ {doc?.insurance_balance || 0.00}</TxtSpan> 
                  </TableBodyCell>
                  <TableBodyCell padding="16px 0px 16px 38px" bodered>
                    <Button
                      background="#FFF"
                      borderRadius="4px"
                      color="#2C7BE5"
                      border="1px solid #2C7BE5"
                      padding="6px"
                      fontWeight="600"
                      marginRight="10px"
                      onClick={() => handleDownload(doc.pdf_link, doc.id)}
                    >
                      Download
                    </Button>
                    <Button
                      background="#2C7BE5"
                      borderRadius="4px"
                      color="#ffffff"
                      border="1px solid #2C7BE5"
                      padding="6px"
                      fontWeight="600"
                      onClick={() => handleView(doc.pdf_link)}
                    >
                      View
                    </Button>
                  </TableBodyCell>
                </TableRow>
              ))
            ) : ( // ✅ Show empty state if no data is available
              <TableRow>
                <TableBodyCell colSpan={5} textAlign="center">
                  <EmptyData />
                </TableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Div>
  );
};

export default StatementTable;
