import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import {
  setDocumentslist,
  setPreviewDownload,
  setPreviewDocument,
  setViewEditDocument,
  SetSignatureSaveSignId,
  setDeleteTempDoc,
  setDownLoadLoading,
  setDownloadLabCredentials,
  Refresh,
} from "../../StateManagement/Reducers/DocumentsState";

import {
  DocumentList_Api,
  InsertDocuments_Api,
  UpdateDocument_Api,
  DeleteDocument_Api,
  DownloadDocument_Api,
  PreViewDocument_Api,
  ViewEdit_Api,
  SignDocument_Api,
  FirstSaveDocument_Api,
  DeleteTempDocument_Api,
  DeleteLabDocument_Api,
  DownloadCredential_Api,
  DownloadFileApi,
  DownloadLabCredential_Api,
} from "../Apis/DocumentsApi";

export function* DocumentListApi({ payload }) {
  const data = payload;
  let Doc_type = Encrypt_Value(data, "vozoportal");
  try {
    const res = yield call(DocumentList_Api, Doc_type);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(setDocumentslist(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setDocumentslist([]));
  }
}

export function* InsertDocumentsApi({ payload }) {
  const File_data = payload;

  try {
    const res = yield call(InsertDocuments_Api, File_data);
    if (res.status === 200) {
      if (res.data === "file added") res.data = 1;
      yield put(Refresh(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* UpdateDocumentApi({ payload }) {
  const data = payload;
  let row_Id = Encrypt_Value(data, "vozoportal");
  try {
    const res = yield call(UpdateDocument_Api, row_Id);
    if (res.status === 200) {
      if (res.data === "success") res.data = 2;
      yield put(Refresh(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* DeleteDocumentApi({ payload }) {
  const Data = Encrypt_Value(payload, "vozoportal");
  try {
    const res = yield call(DeleteDocument_Api, Data);
    if (res.status === 200) {
      if (res.data === "success") res.data = 3;
      yield put(Refresh(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* DownloadDocumentApi({ payload }) {
  const Doc_Data = Encrypt_Value(payload, "vozoportal");
  try {
    const res = yield call(DownloadDocument_Api, Doc_Data);
    if (res.status === 200) {
      yield put(setPreviewDownload(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PreViewDocumentApi({ payload }) {
  try {
    const res = yield call(PreViewDocument_Api, payload);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(setPreviewDocument(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* ViewEditApi({ payload }) {
  try {
    const res = yield call(ViewEdit_Api, payload);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(setViewEditDocument(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SignDocumentApi({ payload }) {
  const Doc_Data = Encrypt_Value(payload, "vozoportal");

  try {
    const res = yield call(SignDocument_Api, Doc_Data);
    if (res.status === 200) {
      if (res.data === "success") res.data = 4;
      yield put(Refresh(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}
export function* FirstSaveDocumentApi({ payload }) {
  const Doc_Data = Encrypt_Value(payload, "vozoportal");

  try {
    const res = yield call(FirstSaveDocument_Api, Doc_Data);
    if (res.status === 200) {
      if (res.data === "success") res.data = 5;
      if (payload.forSign === "Yes") {
        yield put(SetSignatureSaveSignId(res.data));
      } else {
        res.data = 5;
        yield put(Refresh(res.data));
      }
    }
  } catch (e) {
    console.log(e.message);
    yield put(Refresh(e.message));
  }
}

export function* DeleteTempDocumentApi({ payload }) {
  const Doc_Data = Encrypt_Value(payload, "vozoportal");

  try {
    const res = yield call(DeleteTempDocument_Api, Doc_Data);
    if (res.status === 200) {
      yield put(setDeleteTempDoc(res.data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* DeleteLabDocumentApi({ payload }) {
  const Doc_Data = Encrypt_Value(payload, "vozoportal");

  try {
    const res = yield call(DeleteLabDocument_Api, Doc_Data);
    if (res.status === 200) {
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* DownloadCredantialApi({ payload }) {
  const Doc_Data = Encrypt_Value(payload, "vozo");

  try {
    const res = yield call(DownloadCredential_Api, Doc_Data);
    if (res.status === 200) {
      let data = Decrypt_Value(res.data, "vozo");

      let firstDot = window.location.hostname.split(".");
      let DBhost;

      if (
        firstDot[0] === "localhost" ||
        firstDot[0] === "test" ||
        firstDot[0] === "mumbai" ||
        firstDot[0] === "production" ||
        firstDot[0] === "ehr" ||
        firstDot[0] === "ehr1" ||
        firstDot[0] === "dev"
      ) {
        DBhost = "default";
      } else {
        DBhost = firstDot[0];
      }

      let article = {
        access_token: data.pdf.access_token,
        url: data.pdf.pdf,
        site: DBhost,
      };

      const resp1 = yield call(DownloadFileApi, article);
      if (resp1.status === 200) {
        yield put(setDownLoadLoading(false));
        window.open(
          process.env.REACT_APP_BACKEND_FILE_ACCESS +
            "/healthgorilla/output.pdf"
        );
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* DownloadLabCredantialApi({ payload }) {
  console.log(payload);
  const Doc_Data = Encrypt_Value(payload, "vozo");

  try {
    const res = yield call(DownloadLabCredential_Api, Doc_Data);
    if (res.status === 200) {
      let data = Decrypt_Value(res.data, "vozo");
      yield put(setDownloadLabCredentials(data));
    }
  } catch (e) {
    console.log(e.message);
  }
}
